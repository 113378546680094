// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-expertise-jsx": () => import("./../../../src/pages/expertise.jsx" /* webpackChunkName: "component---src-pages-expertise-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/Post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

